<template>
  <div class="total-usage-container">
    <h3>Total Usage</h3>
    <div class="usage-box">
      <ul>
        <li v-for="(item, index) in totalUsage" :key="index">
          {{ item.name }}: {{ item.quantity }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    completedOrders: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    totalUsage() {
      const allItems = {
        snacks: {},
        beverages: {},
        alcohol: {},
        miniBottles: {},
      };

      this.completedOrders.forEach(order => {
        this.addItemsToUsage(allItems.snacks, order.snacks);
        this.addItemsToUsage(allItems.beverages, order.beverages);
        this.addItemsToUsage(allItems.alcohol, order.alcohol);
        this.addItemsToUsage(allItems.miniBottles, order.miniBottles);
      });

      return this.combineItems(allItems);
    },
  },
  methods: {
    addItemsToUsage(usage, items) {
      if (!items) return;
      Object.entries(items).forEach(([name, quantity]) => {
        if (quantity > 0) {
          if (!usage[name]) {
            usage[name] = quantity;
          } else {
            usage[name] += quantity;
          }
        }
      });
    },
    combineItems(allItems) {
      return Object.entries(allItems)
        .flatMap(([, items]) =>
          Object.entries(items).map(([name, quantity]) => ({
            name,
            quantity,
          }))
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
};
</script>

<style scoped>
.total-usage-container {
  margin-top: 30px;
  padding: 10px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.total-usage-container h3 {
  margin-bottom: 10px;
  text-align: center;
}

.usage-box {
  padding: 15px;
  border: 2px solid #3498db;
  border-radius: 10px;
  background-color: #ffffff;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

li {
  font-size: 16px;
  margin-bottom: 5px;
}
</style>
