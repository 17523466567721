<template>
  <div class="container">
    <!-- Clear All Orders Button with confirmation -->
    <button class="clear-orders-button" @click="confirmClearOrders">Clear All Orders</button>

    <!-- Seat Map -->
    <h2>Seat Map</h2>
    <div class="seat-map">
      <div v-for="seat in seatMap" :key="seat" class="seat">
        <button :class="getSeatStatus(seat)" @click="openInitialPrompt(seat)">
          {{ seat }}
          <br />
          <small v-if="seatOrders[seat] && seatOrders[seat].timestamp">{{ seatOrders[seat].timestamp }}</small>
        </button>
      </div>
    </div>

    <!-- Order Summary Toggle Button -->
    <button class="pill-summary-button" @click="toggleSummary">
      {{ showOrderSummary ? 'Hide Order Summary' : 'Show Order Summary' }}
    </button>

    <!-- Order Summary Section -->
    <div v-if="showOrderSummary" class="order-summary">
      <OrderSummary :orders="seatOrders" @markComplete="markOrderComplete" />
    </div>

    <!-- Initial Prompt Popup -->
    <div v-if="showInitialPrompt" class="popup-overlay">
      <div class="popup-content">
        <h3>Would the guest like a snack/beverage?</h3>
        <div class="button-group">
          <button @click="proceedToOrder('yes')">Yes</button>
          <button @click="setNoOrder('No, not right now')">No, not right now</button>
          <button @click="setNoOrder('No, the guest is sleeping')">No, the guest is sleeping</button>
        </div>
      </div>
    </div>

    <!-- Order Popup Component -->
    <OrderPopup ref="orderPopup" :seat="selectedSeat" @submitOrder="storeOrder" />

    <!-- Total Usage -->
    <div v-if="allCompletedOrders.length > 0" class="total-usage">
      <!-- Changed to match the Order Summary header style -->
      <TotalUsage :completedOrders="allCompletedOrders" />
    </div>
  </div>
</template>

<script>
import OrderPopup from './OrderPopup.vue';
import OrderSummary from './OrderSummary.vue';
import TotalUsage from './TotalUsage.vue';

export default {
  components: {
    OrderPopup,
    OrderSummary,
    TotalUsage,
  },
  data() {
    return {
      seatMap: ['1A', '1C', '1D', '1F', '2A', '2C', '2D', '2F', '3A', '3C', '3D', '3F'],
      seatOrders: {},
      selectedSeat: null,
      showInitialPrompt: false,
      showOrderSummary: false,
      allCompletedOrders: [], // Store all completed orders for Total Usage
    };
  },
  methods: {
    openInitialPrompt(seat) {
      this.selectedSeat = seat;
      this.showInitialPrompt = true;
    },
    proceedToOrder(response) {
      this.showInitialPrompt = false;
      this.$emit('orderResponse', { seat: this.selectedSeat, response }); // Emit the yes response
      if (response === 'yes') {
        this.$refs.orderPopup.openPopup();
      }
    },
    setNoOrder(reason) {
      const timestamp = new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
      this.seatOrders[this.selectedSeat] = { reason, timestamp, submitted: false, completed: false };
      this.showInitialPrompt = false;
      this.$emit('orderResponse', { seat: this.selectedSeat, response: 'no' }); // Emit no response
    },
    storeOrder(order) {
      const timestamp = new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });

      if (!this.seatOrders[this.selectedSeat]) {
        this.seatOrders[this.selectedSeat] = {};
      }

      if (this.seatOrders[this.selectedSeat].reason) {
        delete this.seatOrders[this.selectedSeat].reason;
      }

      this.seatOrders[this.selectedSeat] = { ...this.seatOrders[this.selectedSeat], ...order, timestamp, submitted: true, completed: false };
    },
    toggleSummary() {
      this.showOrderSummary = !this.showOrderSummary;
    },
    getSeatStatus(seat) {
      const order = this.seatOrders[seat];
      if (!order) return 'default-seat';
      if (order.reason === 'No, the guest is sleeping') return 'sleeping-seat';
      if (order.reason === 'No, not right now') return 'no-order-seat';
      return order.completed ? 'completed-seat' : 'submitted-seat';
    },
    markOrderComplete(seat) {
      if (this.seatOrders[seat]) {
        this.seatOrders[seat].completed = true;

        // Add the completed order to the total usage tracking
        this.allCompletedOrders.push(this.seatOrders[seat]);
      }
    },
    confirmClearOrders() {
      if (confirm('Are you sure you want to clear all orders?')) {
        this.seatOrders = {};
        this.allCompletedOrders = []; // Clear total usage tracking when clearing orders
      }
    },
  },
};
</script>

<style scoped>
/* General Styles */
.container {
  text-align: center;
}

/* Seat Map Layout */
.seat-map {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 10px;
}

/* Seat Styling */
button {
  padding: 25px 25px;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  width: auto;
  height: 100px;
  border: none;
}

.default-seat {
  background-color: #3498db;
}

.submitted-seat {
  background-color: rgb(4, 49, 184);
}

.completed-seat {
  background-color: green;
}

.no-order-seat {
  background-color: black;
}

.sleeping-seat {
  background-color: red;
}

.seat-map .seat:nth-child(2n+2) {
  margin-right: 10px;
}

small {
  display: block;
  font-size: 12px;
  color: white;
  font-weight: bold;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  border: 2px solid #3498db;
  padding: 20px;
  border-radius: 10px;
  max-height: 80%;
  overflow-y: auto;
  width: 90%;
  max-width: 500px;
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.button-group button {
  width: 80%;
  padding: 15px;
  font-size: 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-group button:hover {
  background-color: #2980b9;
}

.clear-orders-button {
  background-color: transparent;
  color: #e74c3c;
  padding: 5px 15px;
  font-size: 12px;
  border: 2px solid #e74c3c;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  width: auto;
  min-width: 100px;
  line-height: 1;
  height: 30px;
}

.clear-orders-button:hover {
  background-color: #e74c3c;
  color: white;
}

.pill-summary-button {
  padding: 8px 50px;
  background-color: #bbb;
  color: #333;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-style: oblique;
  margin-top: 50px;
  width: auto;
  height: 50px;
  white-space: nowrap;
}

.pill-summary-button:hover {
  background-color: #aaa;
}

.order-summary {
  margin-top: 20px;
  text-align: left;
  font-family: 'Arial', sans-serif; /* Match the font */
  font-size: 20px;
}

.total-usage {
  margin-top: 30px;
  padding: 10px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: left; /* Left-align the Total Usage list */
  font-family: 'Arial', sans-serif; /* Match the font */
  font-size: 20px;
}
</style>
