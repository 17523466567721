// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import SeatMap from '../components/SeatMap.vue';
import OrderSummary from '../components/OrderSummary.vue';

const routes = [
  {
    path: '/',
    name: 'SeatMap',
    component: SeatMap
  },
  {
    path: '/summary',
    name: 'OrderSummary',
    component: OrderSummary,
    props: true  // This ensures seatOrders are passed as props
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
