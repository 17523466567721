<template>
  <div id="app">
    <h1 align="center">1st Class Service Tool</h1>

    <!-- How it works button -->
    <button class="info-button" @click="showInfo = true">Click here to find out how this works</button>

    <!-- SeatMap component -->
    <SeatMap />

    <!-- Info Popup -->
    <div v-if="showInfo" class="info-popup-overlay">
      <div class="info-popup-content">
        <div class="info-popup-header">
          <h3><strong>Seat Color Legend</strong></h3>
          <button class="close-info-button" @click="showInfo = false">X</button>
        </div>

        <ul class="legend-list">
          <li><span class="legend-color default-seat"></span> Light Blue = Default Seat</li>
          <li><span class="legend-color submitted-seat"></span> Dark Blue = Order Submitted</li>
          <li><span class="legend-color no-order-seat"></span> Black = Guest Doesn't Want Anything Right Now</li>
          <li><span class="legend-color sleeping-seat"></span> Red = Guest Is Asleep</li>
          <li><span class="legend-color completed-seat"></span> Green = Order Completed</li>
        </ul>

        <h4><strong>Here’s how it works:</strong></h4>
        <p><strong>1.</strong> Click on the first seat you’d like to begin with. Follow the prompts. It’s pretty self-explanatory. Look at the seat color legend to tell what the colors mean. You’ll notice it timestamps anytime a seat status change. The purpose of this is mainly to tell you on those long flights, how long it was since you last gave the guest anything. It also helps to know when you went through and checked if they are still doing ok without anything, or if they are still sleeping.</p>
        <p><strong>2.</strong> Once your orders are done, scroll down and “show order summary” (if you didn’t already cause you were nosey and wanted to see how it worked before finishing… naughty!). You’ll see nicely arranged all the orders you took and a fancy “complete” button to press when you they are done and delivered. </p>
        <p><strong>3.</strong> (I bet you can’t guess what you do next) Fulfill the orders and deliver them to the guest, then you mark “complete.” TA DA!</p>
        <p><strong>4.</strong> A couple things to note, if a guest wants to place another order (or was sleeping or not wanting before when you asked them, and wants to place their first order now), simply tap again on the seat, and follow the prompts. The order will be placed below with that “complete” button so you know which are the new ones you need to do. For orders after the first, it will keep overwriting the previous order at that seat. </p>
        <p><strong>5.</strong> Lastly, the life saver in my opinion to be honest. The master usage summary. This keeps a tally of all items served during that flight. This makes it super easy to go into the bistro app, put in all your items and quantities, do your ascent 100% reduction, and bam. All done. And when comes the time to do the next flight, just clear the orders at the top, and you’re ready to do it all over again.</p>
        <p><strong>6.</strong> Then go do trash, cause you’re FA2 and FA3 have probably only done like 4 rows because you were so speedy. </p>
        <p><strong>7.</strong> Give me your overnight IAD trips (not required, but desired).</p>

        <h4><strong>FAQ’s</strong></h4>
        <p><strong>Why did Matt create this?</strong></p>
        <p>Cause he sucks at remembering orders and it takes way too long to type it on the iPad just to go back and try to decipher what was typed half the time. Not to mention, the bistro app step and putting in what you used and having that deer in headlights like “What did I even just serve on this flight” look… the worst!! Or, trying to do it as you go in the bistro app just for your iPad to fall on the floor and crack the screen, or lord knows so many things could go wrong here. So, let’s not speculate anymore. He needed it, ok?</p>
        <p><strong>How can I use this to help me even if I don’t have a problem remembering orders?</strong></p>
        <p>Even just using it for the master total tallying for entry into the bistro app can save a lot of time. </p>
        <p><strong>Can it be used on the few A220s without wifi?</strong></p>
        <p>Yes, as long as you load the page while you are on the ground before you lose service. It fully functions without an internet connection after that.</p>
        <p><strong>I hit the backspace button and it messed everything up. Can I get back what I did?</strong></p>
        <p>No, so don’t hit the backspace button. If you need to clear orders out after your flight, just select the Clear All Orders button.</p>
        <p><strong>Is this webapp perfect?</strong></p>
        <p>No, cause it was literally the brainchild of a non-programming person. So don’t come at me with your rude feedback. However, constructive feedback that can improve it is welcome. (I can’t guarantee I can do anything about it since I am learning as I go here).</p>

        <button class="close-info-bottom-button" @click="showInfo = false">Close</button>
      </div>
    </div>
    <!-- Footer -->

    <footer class="app-footer">
      <p>This tool is not officially endorsed to be used by any airline. It's also not the property of any airline, and won't be unless I get paid for it.</p>
    </footer>
  </div>
</template>

<script>
import SeatMap from './components/SeatMap.vue';

export default {
  name: 'App',
  components: {
    SeatMap
  },
  data() {
    return {
      showInfo: false, // Controls the visibility of the popup
    };
  },
  mounted() {
    // Matomo Tracking Code
    var _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function() {
      var u="//mattbello.com/analytics/";
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', '1']);  // Replace '1' with your Matomo site ID
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();
  }
};
</script>

<style>

/* Apply the background image to the main app container */
#app {
  background-image: url('/public/background.png'); /* Reference the image from the public folder */
  background-size: cover;  /* Ensure the background covers the entire container */
  background-position: center;  /* Center the image */
  background-repeat: no-repeat;  /* Prevent the image from repeating */
  min-height: 100vh;  /* Ensure it covers the full height of the viewport */
}

/* Info Button Styling */
.info-button {
  font-size: 14px;
  padding: 5px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: 10px auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

.info-button:hover {
  background-color: #2980b9;
}

/* Popup Styles */
.info-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.info-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #3498db;
  width: 90%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto; /* This makes the popup scrollable */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  position: relative;
}

.info-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Legend Styling */
.legend-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.legend-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.legend-color {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 5px;
}

.default-seat {
  background-color: #3498db;
}

.submitted-seat {
  background-color: rgb(4, 49, 184);
}

.completed-seat {
  background-color: green;
}

.no-order-seat {
  background-color: black;
}

.sleeping-seat {
  background-color: red;
}

/* Close Button Styling */
.close-info-button {
  padding: 5px 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
}

.close-info-bottom-button {
  margin-top: 20px;
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-info-bottom-button:hover {
  background-color: #2980b9;
}

.close-info-button:hover {
  background-color: #c0392b;
}

/* FAQ Formatting */
h4 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

p {
  margin-bottom: 10px;
  line-height: 1.5;
}
/* Footer Styling */
.app-footer {
  text-align: center;
  font-size: 12px;
  color: #777;
  margin-top: 20px;
  padding: 10px;
}

.app-footer p {
  margin: 0;
}
</style>
