<template>
  <div v-if="isVisible" class="popup-overlay">
    <div class="popup-content">
      <h3>Order for Seat {{ seat }}</h3>

      <!-- Snacks Section -->
      <h4>Snacks</h4>
      <div v-for="(quantity, snack) in order.snacks" :key="snack" class="item">
        <div class="item-name">{{ snack }}</div>
        <div class="quantity-controls">
          <button class="quantity-btn" @click="decreaseQuantity(order.snacks, snack)">-</button>
          <span class="quantity-text">{{ quantity }}</span>
          <button class="quantity-btn" @click="increaseQuantity(order.snacks, snack)">+</button>
        </div>
      </div>

      <!-- Beverages Section -->
      <h4>Beverages</h4>
      <div v-for="(quantity, beverage) in order.beverages" :key="beverage" class="item">
        <div class="item-name">{{ beverage }}</div>
        <div class="quantity-controls">
          <button class="quantity-btn" @click="decreaseQuantity(order.beverages, beverage)">-</button>
          <span class="quantity-text">{{ quantity }}</span>
          <button class="quantity-btn" @click="increaseQuantity(order.beverages, beverage)">+</button>
        </div>

        <!-- Customizations for Beverages (Ice, Light Ice, No Ice) -->
        <div v-if="quantity >= 1 && beverage !== 'Hot Coffee' && beverage !== 'Hot Tea'" class="customizations">
          <div class="customization-group">
            <label><input type="radio" v-model="order.customizations.ice[beverage]" value="ice" /> Ice</label>
            <label><input type="radio" v-model="order.customizations.ice[beverage]" value="light ice" /> Light Ice</label>
            <label><input type="radio" v-model="order.customizations.ice[beverage]" value="no ice" /> No Ice</label>
          </div>
        </div>

        <!-- Customization for Hot Coffee -->
        <div v-if="beverage === 'Hot Coffee' && quantity >= 1" class="customizations">
          <div class="customization-group">
            <label>Cream:</label>
            <div class="quantity-controls">
              <button class="quantity-btn" @click="decreaseCustomization(order.customizations.cream, beverage)">-</button>
              <span class="quantity-text">{{ order.customizations.cream[beverage] || 0 }}</span>
              <button class="quantity-btn" @click="increaseCustomization(order.customizations.cream, beverage)">+</button>
            </div>
          </div>
          <div class="customization-group">
            <label>Sugar:</label>
            <div class="quantity-controls">
              <button class="quantity-btn" @click="decreaseCustomization(order.customizations.sugar, beverage)">-</button>
              <span class="quantity-text">{{ order.customizations.sugar[beverage] || 0 }}</span>
              <button class="quantity-btn" @click="increaseCustomization(order.customizations.sugar, beverage)">+</button>
            </div>
          </div>
          <div class="customization-group">
            <label>Equal:</label>
            <div class="quantity-controls">
              <button class="quantity-btn" @click="decreaseCustomization(order.customizations.equal, beverage)">-</button>
              <span class="quantity-text">{{ order.customizations.equal[beverage] || 0 }}</span>
              <button class="quantity-btn" @click="increaseCustomization(order.customizations.equal, beverage)">+</button>
            </div>
          </div>
        </div>

        <!-- Customization for Hot Tea -->
        <div v-if="beverage === 'Hot Tea' && quantity >= 1" class="customizations">
          <div class="customization-group">
            <label>Tea Type:</label>
            <select v-model="order.customizations.teaType[beverage]">
              <option>Green Tea</option>
              <option>English Black Tea</option>
            </select>
          </div>

          <div class="customization-group">
            <label>Cream:</label>
            <div class="quantity-controls">
              <button class="quantity-btn" @click="decreaseCustomization(order.customizations.cream, beverage)">-</button>
              <span class="quantity-text">{{ order.customizations.cream[beverage] || 0 }}</span>
              <button class="quantity-btn" @click="increaseCustomization(order.customizations.cream, beverage)">+</button>
            </div>
          </div>

          <div class="customization-group">
            <label>Sugar:</label>
            <div class="quantity-controls">
              <button class="quantity-btn" @click="decreaseCustomization(order.customizations.sugar, beverage)">-</button>
              <span class="quantity-text">{{ order.customizations.sugar[beverage] || 0 }}</span>
              <button class="quantity-btn" @click="increaseCustomization(order.customizations.sugar, beverage)">+</button>
            </div>
          </div>

          <div class="customization-group">
            <label>Equal:</label>
            <div class="quantity-controls">
              <button class="quantity-btn" @click="decreaseCustomization(order.customizations.equal, beverage)">-</button>
              <span class="quantity-text">{{ order.customizations.equal[beverage] || 0 }}</span>
              <button class="quantity-btn" @click="increaseCustomization(order.customizations.equal, beverage)">+</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Alcoholic Beverages Section -->
      <h4>Alcoholic Beverages</h4>
      <div v-for="(quantity, alcohol) in order.alcohol" :key="alcohol" class="item">
        <div class="item-name">{{ alcohol }}</div>
        <div class="quantity-controls">
          <button class="quantity-btn" @click="decreaseQuantity(order.alcohol, alcohol)">-</button>
          <span class="quantity-text">{{ quantity }}</span>
          <button class="quantity-btn" @click="increaseQuantity(order.alcohol, alcohol)">+</button>
        </div>
      </div>

      <!-- Mini Bottles Section -->
      <h4>Mini Bottles</h4>
      <div v-for="(quantity, bottle) in order.miniBottles" :key="bottle" class="item">
        <div class="item-name">{{ bottle }}</div>
        <div class="quantity-controls">
          <button class="quantity-btn" @click="decreaseQuantity(order.miniBottles, bottle)">-</button>
          <span class="quantity-text">{{ quantity }}</span>
          <button class="quantity-btn" @click="increaseQuantity(order.miniBottles, bottle)">+</button>
        </div>
      </div>

      <!-- Floating Submit and Close Buttons -->
      <div class="popup-buttons">
        <button class="popup-submit" @click="submitOrder">Submit</button>
        <button class="popup-close" @click="closePopup">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import itemsData from '../itemsData';

export default {
  name: 'OrderPopup',
  props: ['seat'],
  data() {
    return {
      isVisible: false,
      order: JSON.parse(JSON.stringify(itemsData)), // Deep copy of itemsData
    };
  },
  methods: {
    openPopup() {
      this.isVisible = true;
      // Reset order form when popup is opened
      this.order = JSON.parse(JSON.stringify(itemsData)); // Reset order to the default state
    },
    closePopup() {
      this.isVisible = false;
    },
    increaseQuantity(category, item) {
      category[item]++;
      if (!this.order.customizations.ice[item] && item !== 'Hot Coffee' && item !== 'Hot Tea') {
        this.order.customizations.ice[item] = 'ice'; // Set default ice customization
      }
    },
    decreaseQuantity(category, item) {
      if (category[item] > 0) {
        category[item]--;
        if (category[item] === 0) {
          delete this.order.customizations.ice[item]; // Remove ice customization if quantity is reduced to 0
        }
      }
    },
    increaseCustomization(customization, beverage) {
      if (!customization[beverage]) customization[beverage] = 0;
      customization[beverage]++;
    },
    decreaseCustomization(customization, beverage) {
      if (customization[beverage] > 0) customization[beverage]--;
    },
    submitOrder() {
      this.$emit('submitOrder', this.order); // Emit order to parent component
      this.closePopup();
    },
  },
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  border: 2px solid #3498db;
  padding: 20px;
  border-radius: 10px;
  max-height: 80%;
  overflow-y: auto;
  width: 90%;
  max-width: 600px;
}
.popup-content h1, h2, h3, h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;  /* Bold for headings */
  color: #333;
}

.popup-content p, .item-name, .quantity-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;  /* Regular for body text */
  color: #555;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h3, h4 {
  font-size: 24px;
}

.item-name {
  font-size: 18px;
}

.quantity-text {
  font-size: 20px;
  font-weight: 600; /* Slightly heavier */
}

.popup-content {
  font-size: 16px;
}

.item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.item-name {
  font-size: 18px;
  margin-bottom: 5px;
  flex: 1;
  text-align: right; /* Right align the item names */
  padding-right: 15px; /* Add some padding to bring it closer */
  align-self: center; /* Vertically center align */
}

.quantity-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align buttons to the right */
  flex: 1;
}

.quantity-btn {
  padding: 5px 15px;
  font-size: 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 0 5px;
  cursor: pointer;
}

.quantity-btn:hover {
  background-color: #2980b9;
}

.quantity-text {
  font-size: 22px;
  margin: 0 10px;
  font-weight: bold;
  text-align: center;
}

.customizations {
  margin-top: 10px;
  padding-left: 15px;
}

.customization-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 10px;
}

.popup-buttons {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 90%;
  padding: 0 20px;
}

.popup-submit {
  background-color: #3498db;
  color: white;
  padding: 20px 40px;
  font-size: 22px;
  border-radius: 5px;
  margin-right: 20px;
}

.popup-close {
  background-color: #e74c3c;
  color: white;
  padding: 20px 40px;
  font-size: 22px;
  border-radius: 5px;
}
</style>
